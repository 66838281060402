import React, { useContext } from "react"
import PropTypes from "prop-types"
import CssBaseline from "@material-ui/core/CssBaseline"
import Notifier from "services/Notifier"
import store from "store"

import { isEmpty } from "lodash"
import { SnackbarProvider } from "notistack"
import { Backdrop, CircularProgress } from "@material-ui/core"
import { ThemeProvider, makeStyles } from "@material-ui/core/styles"
import { Provider, useSelector } from "react-redux"
import { UserProvider, UserContext } from "services/User/context"
import { theme } from "../theme"
import "leaflet/dist/leaflet.css"
import "../styles/global.scss"
import "leaflet-geosearch/dist/geosearch.css"
import "font-awesome/css/font-awesome.min.css"

import * as Sentry from "@sentry/node"
import { RewriteFrames } from "@sentry/integrations"
import getConfig from "next/config"

// import "leaflet.locatecontrol/dist/L.Control.Locate.min.css"

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const config = getConfig()
  const distDir = `${config?.serverRuntimeConfig?.rootDir}/.next` ?? undefined

  Sentry.init({
    environment: process.env.NODE_ENV,
    enabled: process.env.NODE_ENV !== "development",
    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame.filename.replace(distDir, "app:///_next")
          return frame
        },
      }),
    ],
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  })
}

export default function App({ Component, pageProps }) {
  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side")
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles)
    }
  }, [])

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <UserProvider>
          <Component {...pageProps} />
          <UIWrapper />
        </UserProvider>
      </ThemeProvider>
    </Provider>
  )
}

App.propTypes = {
  Component: PropTypes.func.isRequired,
  pageProps: PropTypes.object.isRequired,
}

/**
 * UI Wrapper
 */

const useUIStyles = makeStyles((theme) => ({
  backdrop: {
    color: "#fff",
  },
}))

const UIWrapper = () => {
  const { user = {} } = useContext(UserContext)

  const classes = useUIStyles()
  const backdrop = useSelector((store) => store.ui.backdrop || false)

  if (!isEmpty(user)) {
    Sentry.configureScope(function (scope) {
      scope.setUser({ email: user.email })
    })
  }

  return (
    <>
      <SnackbarProvider maxSnack={3}>
        <Notifier />
        <Backdrop className={classes.backdrop} open={backdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </SnackbarProvider>
    </>
  )
}
