import { configureStore } from "@reduxjs/toolkit"

import uiReducer from "slices/ui"

export default configureStore({
  reducer: {
    ui: uiReducer,
  },
  devTools: true,
})
