import { createMuiTheme, createStyles, makeStyles } from "@material-ui/core/styles"

// import { StylesProvider, createGenerateClassName } from '@material-ui/core/styles';

// const generateClassName = createGenerateClassName({
//   productionPrefix: 'c',
//   dangerouslyUseGlobalCSS: true
// });

// <JssProvider generateClassName={generateClassName}></JssProvider>

export const theme = createMuiTheme({
  box: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    boxShadow: "0px 0px 4px 1px #efefef",
    borderRadius: 10,
    padding: 8,
  },
  palette: {
    common: { black: "#000", white: "#fff" },
    background: { paper: "#fff", default: "#fafafa" },
    primary: {
      light: "#7986cb",
      main: "#0281d0",
      dark: "#303f9f",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff4081",
      main: "rgb(0, 91, 245)",
      dark: "#c51162",
      contrastText: "#fff",
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#fff",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
    },
  },
  props: {
    // Name of the component ⚛️
    MuiButtonBase: {
      // The default props to change
      disableRipple: true, // No more ripple, on the whole application 💣!
    },
  },
  overrides: {
    // Style sheet name ⚛️
    MuiFormHelperText: { root: { margin: 10 } },
    MuiGrid: {
      root: {
        "& .form": {
          "& .MuiTypography-body1": {
            margin: 10,
          },
        },
      },
    },
    MuiTypography: {
      h2: {
        fontSize: "2em",
        fontWeight: "bold",
      },
      h3: {
        fontSize: "1.7em",
        fontWeight: "bold",
        marginTop: 0,
      },
      h4: {
        fontSize: "1.3em",
        fontWeight: "bold",
        marginTop: 0,
      },
      body2: {
        // color: "#dedede",
      },
    },
    MuiOutlinedInput: {
      root: {},
      notchedOutline: {
        "&:hover": {
          borderColor: "red",
        },
        borderRadius: 10,
        border: "1px solid rgba(0, 0, 0, 0.23)",
        boxShadow: "0px 0px 4px 1px #efefef",
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: "1rem",
      },
    },
    MuiButton: {
      // Name of the rule
      contained: {
        // Some CSS
        // background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
        backgroundColor: "white",
        borderRadius: 6,
        border: 0,
        color: "#333",
        height: 48,
        padding: "0 30px",
        // boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
        width: "100%",
      },
    },
    MuiBackdrop: {
      root: { zIndex: 10 },
    },
    MuiDialog: {
      paper: { zIndex: 15 },
    },
  },
})

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      // backgroundColor: theme.color.red,
      background: theme.background,
      border: 0,
      fontSize: 16,
      borderRadius: 3,
      boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
      color: "white",
      height: 48,
      padding: "0 30px",
    },
  })
)
